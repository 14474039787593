
@import '../../styles/global.scss';

.full-width-image-section{
    display: flex;
    background-color: $background-grey;
    flex-direction: column;

    @include desktop{
        @include desktop-section-container;
        flex-direction: column;
    }

    @include tablet{
        @include tablet-section-container;
    }

    &__section-container{
        margin: auto;
        display: flex;
        flex-direction: column;
        gap: $spacing-24;
        padding: $spacing-32 $spacing-32 $spacing-24 $spacing-32;

        @include desktop{
            max-width: $desktop-total-width;
            gap: $spacing-48;
        }

        @include tablet{
            padding: 0 $spacing-48;
        }
    }

    &__text-wrapper{
        display: flex;
        flex-direction: column;
        gap: $spacing-8;

        @include desktop{
            @include desktop-text-wrapper;
        }
        
        &--header{
            text-align: center;
        }

        &--body{
            text-align: center;
        }
    }

    &__image-wrapper{
        width: 100%;
        display: flex;

        @include desktop{
            max-width: $desktop-total-width;
            display: flex;
            justify-content: center;
            margin: auto;
            width: 100%;
        }

        &--image{
            width: 100%;

            @include desktop{
                width: 100%;
            }
        }
    }
}