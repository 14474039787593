@import '../../styles/global.scss';

.hero{

    @include mobile-section-container-no-bottom-spacing;

    @include desktop{
        @include desktop-section-container-no-bottom-spacing;
        flex-direction: column;

    }

    @include tablet{
        @include tablet-section-container-no-bottom-spacing;
    }

    &__section-container{
        margin: auto;
        width: 100%;
        z-index: 1;
        display: flex;
        flex-direction: column;
        gap: $spacing-32;

        @include desktop{
            max-width: $desktop-total-width;
            gap: $spacing-48;
        }

        @include tablet{
            padding: 0 $spacing-48;
        }
    }

    &__text-wrapper{

        display: flex;
        flex-direction: column;
        gap: $spacing-8;

        @include desktop{
            gap: $spacing-16;
            @include desktop-text-wrapper;
        }

        &--header{
            text-align: center;
        }

        &--body{
            text-align: center;
        }
    }

    &__image-wrapper{
        display: flex;
        justify-content: center;

        &--image{
            width: 100%;

            @include desktop{
                width: 80%;
            }

            @include tablet{
                width: 80%;
            }
        }
    }
}