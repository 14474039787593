@import '../../styles/global.scss';

.banner{
    background-color: $background-blue;
    color: $text-invert;
    display: flex;
    justify-content: center;
    padding: $spacing-32;

    @include desktop{
        padding: $spacing-48 0;
        justify-content: center;
    }

    &__secction-container{
        display: flex;
        flex-direction: column;
        max-width: $desktop-total-width-banner;
        width: 100%;
        gap: $spacing-24;
    }

    &__section-upper{
        display: flex;
        flex-direction: column;
        gap: $spacing-8;

        &--body{
            text-align: left;
        }
    }

}