@import '../../styles/global.scss';

.text-link{
    @include body-regular;
    @include link-rm-style;
    color: $text;
    padding: $spacing-12 $spacing-12;
    font-weight: 600;
    margin: 0;
    cursor: pointer;

    &:hover{
        text-decoration: none;
    }

    &__inner{
        padding: $spacing-12 $spacing-12;
        text-align: center;

        @include desktop{
            text-align: left;
        }

        &:hover{
            background-color: rgba(0, 0, 0, 0.042);
            border-radius: 4px;
        }
    }
}