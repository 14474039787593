
@import '../../styles/global.scss';

.cards-section{
    @include mobile-section-container;
    background-color: $background-grey;

    @include desktop{
        @include desktop-section-container;
        flex-direction: column;

    }

    @include tablet{
        @include tablet-section-container;
    }

    &__section-container{
        margin: auto;
        width: 100%;
        z-index: 1;
        display: flex;
        flex-direction: column;
        gap: $spacing-24;

        @include desktop{
            max-width: $desktop-total-width;
            gap: $spacing-48;
        }

        @include tablet{
            padding: 0 $spacing-48;
        }
    }

    &__text-wrapper{
        display: flex;
        flex-direction: column;
        gap: $spacing-8;

        @include desktop{
            @include desktop-text-wrapper;
        }
        
        &--header{
            text-align: center;
        }

        &--body{
            text-align: center;
        }
    }

    &__cards-tile-wrapper{
        display: flex;
        flex-direction: column;
        gap: $spacing-24;
        width: 100%;

        @include desktop{
            gap: $spacing-32;
            flex-direction: row;
        }
    }
}