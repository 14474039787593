@import '../../styles/global.scss';

.nav-desktop{
    display: none;
    width: 16.25rem;
    border-right: 1px solid $divider;

    @include desktop{
        background-color: #f8f8f8;
        height: 100vh;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        position: fixed;
        top: 0;
        z-index: 1;
    }

    &__nav-container{

        @include desktop{
            display: flex;
            flex-direction: column;
            height: 100%;
            padding: $spacing-16;
        }
    }

    &__logo-wrapper{

        @include desktop{
            display: flex;
            flex-direction: column;
            padding: $spacing-16 $spacing-24;
            border-bottom: 1px solid $divider ;
            justify-content: flex-start;
        }
    }

    &__nav-wrapper{

        @include desktop{
            display: flex;
            flex-direction: column;
        }
    }

    &__social-container{

        @include desktop{
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: $spacing-24;
            border-top: 1px solid $divider ;
        }
    }

    &__social-wrapper{

        @include desktop{
            display: flex;
            flex-direction: row;
            width: 100%;
            justify-content: space-between;
            align-items: center;
        }
    }

}

.nav-mobile{
    border-bottom: 1px solid $divider;
    background-color: #f8f8f8;
    position: fixed;
    display: block;
    z-index: 100;
    top: 0;
    width: 100%;

    &__nav-wrapper{
        display: flex;
        justify-content: space-between;
        padding: $spacing-16 $spacing-32 $spacing-16 $spacing-8;
    }

    @include desktop{
        display: none;
    }
}

.nav-mobile-drawer{
    z-index: 100;
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: #f8f8f8;
    position: absolute;
    right: 0;
    top: 0;

    @include desktop{
        display: none;
    }

    &.open {
        width: 100%; 
        transition: 0.3s;
    }
      
    &.close {
        width: 0;
        opacity: 0;
        transition: 0.3s;
    }

    &__logo-wrapper{
        display: flex;
        flex-direction: row;
        padding: $spacing-16 $spacing-32 $spacing-16 $spacing-8;
        border-bottom: 1px solid $divider ;
        justify-content: flex-end;
    }

    &__nav-container{
        flex: 1;
        height: 100%;
    }

    &__nav-wrapper{
        display: flex;
        flex-direction: column;
        padding: $spacing-16;

        &--link{
            text-align: center;
        }
    }

    &__social-container{
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: $spacing-24;
        border-top: 1px solid $divider ;
    }

    &__social-wrapper{
        display: flex;
        flex-direction: row;
        width: 100%;
        gap: $spacing-16;
        justify-content: center;
    }
}